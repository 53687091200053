<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Editors component
 */
export default {
  page: {
    title: "Page Content",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      valid: true,
      submitted: false,
      title: "Page Content",
      items: [
        {
          text: "Pages",
          href: "/admin",
        },
        {
          text: "Page Content",
          active: true,
        },
      ],
      pagecontent: "",
      loading: false,
      disable: false,
      pagename: "",
      pageid: "",
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor imagetools",
      ],
      height: 500,
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
    };
  },
  validations: {
    pagecontent: {
      required,
    },
  },
  mounted() {
    if (!this.$route.params.data) {
      return this.$router.push("/page/list");
    }
    this.pagecontent = this.$route.params.data.content;
    this.pagename = this.$route.params.data.sub_type;
    this.pageid = this.$route.params.data.id;
  },
  methods: {
    async save() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.loading = this.disable = true;
      var obj = { content: this.pagecontent };
      var result = await ApiClass.updateFormRequest(
        "admin/pages/update/" + this.pageid,
        true,
        obj
      );
      this.submitted = this.loading = this.disable = false;
      if (result.data.status_code == 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });

        this.$router.push({
          name: "PageShow",
          params: { data: this.pagecontent },
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    onReset(event) {
      event.preventDefault();
      this.submitted = false;
      this.pagecontent = ""; // Empty Fields
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <p>{{ pagename }}</p>
            </div>

            <form @submit.prevent="save" @reset="onReset">
              <!-- Text Editor -->
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.pagecontent.$error }"
              >
                <p class="card-title-desc mt-3">Add Content :</p>
                <editor
                  api-key="no-api-key"
                  id="uuid"
                  :plugins="plugins"
                  tag-name="div"
                  :toolbar="toolbar"
                  v-model="pagecontent"
                  :options="options"
                  :init="{
                    height: 500,
                  }"
                />
              </div>

              <div class="error" v-if="submitted && !$v.pagecontent.required">
                Content is required
              </div>

              <!-- Save Button -->
              <b-button
                class="mt-3 me-3"
                type="submit"
                variant="primary"
                :disabled="disable"
              >
                <span v-if="loading" class="px-4 py-2">
                  <b-spinner label="Spinning"></b-spinner>
                </span>
                <span v-else class="px-4 py-2"> Submit </span>
              </b-button>

              <b-button
                class="mt-3"
                type="reset"
                variant="danger"
                :disabled="disable"
              >
                <span v-if="loading" class="px-4 py-2">
                  <b-spinner label="Spinning"></b-spinner>
                </span>
                <span v-else class="px-4 py-2"> Reset </span>
              </b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style scoped>
iframe#uuid_ifr {
  height: 450px !important;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>